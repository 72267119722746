@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-neutral-900 text-neutral-50 tracking-wide font-sans;
    @apply scroll-smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }
  h1 {
    @apply text-4xl sm:text-5xl md:text-6xl;
  }
  h2 {
    @apply text-3xl sm:text-4xl;
  }
  li {
    @apply cursor-pointer;
  }
  button {
    @apply shadow-xl shadow-gray-400 rounded-xl uppercase bg-gradient-to-tr from-cyan-300 to-cyan-900 text-slate-100;
  }
}

@layer components {
  .about-icon svg {
    @apply h-10 w-10 fill-cyan-200;
  }
}
.app_container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.item-active {
  background: rgb(103, 232, 249);
  color: #000;
}
.menu-active {
  color: rgb(103, 232, 249);
  position: relative;
}
.menu-active:before {
  content: '';
  position: absolute;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: rgb(103, 232, 249);
}
* {
  scroll-behavior: smooth;
}
